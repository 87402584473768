html {
  overflow-x: hidden;
  -webkit-print-color-adjust: exact;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  position: relative;
}

.flex-box {
  display: flex;
}

.flex-box.center-horizontal {
  justify-content: center;
}

.flex-box.center-vertical {
  align-items: center;
}

.setup-flow-form {
  width: 80%;
  max-width: 270px;
}

/* Bumping up from 3 for editable section chart editing */
.highcharts-tooltip-container {
  z-index: 6 !important;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.react-grid-item.react-grid-placeholder {
  background-color: #adadad !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.explo-route-wrapper {
  height: inherit !important;
}

@media print {
  @page {
    size: A4 landscape;
    margin: 0mm;
  }

  .pdfDownloadDashboard {
    overflow: visible !important;
    overflow-y: visible !important;
    display: block !important;
    float: none !important;
  }
}

.bp3-menu-divider {
  margin: 5px -5px !important;
}
